import { BASENAME_PAGE, AMERICAS_PAGE, EUROPE_PAGE, ASEAN_PAGE, AFRICA_PAGE, NML_PAGE, MIDDLE_EAST_PAGE, WORKDAY_EN_PATH } from "../interfaces/Static/routing_paths";

export const AMERCIAS_KEY = 'americas';
export const EUROPE_KEY = 'europe';
export const ASEAN_KEY = 'asia';
export const AFRICA_KEY = 'africa';
export const NML_KEY = 'nml';
export const MIDDLE_EAST_KEY = 'middle-east';

export const ACTIVE_REGIONS = [{
    id: 91,
    name_fm: "page.common.americas",
    name: "Americas",
    value: BASENAME_PAGE + AMERICAS_PAGE,
    pathName: AMERICAS_PAGE,
    region: AMERCIAS_KEY,
    workdayPath: `${WORKDAY_EN_PATH}locationCountry=a30a87ed25634629aa6c3958aa2b91ea&locationCountry=e2adff9272454660ac4fdb56fc70bb51&locationCountry=bc33aa3152ec42d4995f4791a106ed09&locationCountry=e42ad5eac46d4cc9b367ceaef42577c5&locationCountry=1a29bb1357b240ab99a2fa755cc87c0e&locationCountry=53fe09ef12b9408682a1d2439823f2e0&locationCountry=0eb156ca580c4db786a7894bdaa77450`,
    isVisible: true
}, {
    id: 92,
    name_fm: "page.common.europe",
    name: "Europe",
    value: BASENAME_PAGE + EUROPE_PAGE,
    pathName: EUROPE_PAGE,
    region: EUROPE_KEY,
    workdayPath: `${WORKDAY_EN_PATH}locationCountry=a04ea128f43a42e59b1e6a19e8f0b374&locationCountry=49ab063f422741e2aef271de00efeac8&locationCountry=038b0482bfea403abb61c9bcc3d7eb60&locationCountry=0afb2fa656da42e8bfb6d47bd24a26fa&locationCountry=54c5b6971ffb4bf0b116fe7651ec789a&locationCountry=dcc5b7608d8644b3a93716604e78e995&locationCountry=29247e57dbaf46fb855b224e03170bc7&locationCountry=bd34c524a6a04ae6915f5d96fa086199&locationCountry=9db257f5937e4421b2fac64eec6832f8&locationCountry=9696868b09c64d52a62ee13b052383cc&locationCountry=131d5ac7e3ee4d7b962bdc96e498e412&locationCountry=8cd04a563fd94da7b06857a79faaf815&locationCountry=a4051ef996ac40778d4c79e3f2dedfd2&locationCountry=1c026f3b1b8640d8bdfcb95466663e4d&locationCountry=8a0328effd25491fb8e6a08801f08e94&locationCountry=d07f8ca8625e4345b98a91d0558b872a&locationCountry=2e8c5034deb045d49315417c347472ca&locationCountry=6a800a4736884df5826858d435650f45&locationCountry=c2e3bac5bbbb47b29dfc6e8b56a1586e`,
    isVisible: true
}, {
    id: 93,
    name_fm: "page.common.asia_asean",
    name: "ASEAN",
    value: BASENAME_PAGE + ASEAN_PAGE,
    pathName: ASEAN_PAGE,
    region: ASEAN_KEY,
    workdayPath: `${WORKDAY_EN_PATH}locationCountry=b31234dbcdda4da9ba8fa073c5944e36&locationCountry=e56f1daf83e04bacae794ba5c5593560&locationCountry=80938777cac5440fab50d729f9634969&locationCountry=873d0f604e3b458c990cb4d83a5c0f14`,
    isVisible: true
},
{
    id: 94,
    name_fm: "page.common.africa",
    name: "Africa",
    value: BASENAME_PAGE + AFRICA_PAGE,
    pathName: AFRICA_PAGE,
    region: AFRICA_KEY,
    workdayPath: `${WORKDAY_EN_PATH}locationCountry=d865e83093ad42319653b08e61f7db49&locationCountry=4db3c26940b944919b83612cc1193eed`,
    isVisible: true
},
{
    id: 95,
    name_fm: "page.common.japan",
    name: "Japan",
    value: BASENAME_PAGE + NML_PAGE,
    pathName: NML_PAGE,
    region: NML_KEY,
    workdayPath: BASENAME_PAGE + NML_PAGE,
    isVisible: true,
    translation: true,
},
// {
//     id: 95,
//     name_fm: "page.common.japan",
//     name: "Japan",
//     value: "https://www.nissan.co.jp/RECRUIT/",
//     pathName: "https://www.nissan.co.jp/RECRUIT/",
//     region: NML_KEY,
//     workdayPath: "https://www.nissan.co.jp/RECRUIT/",
//     isVisible: true,
//     translation: false,
// },
{
    id: 96,
    name_fm: "page.common.middle_east",
    name: "Middle East",
    value: BASENAME_PAGE + MIDDLE_EAST_PAGE,
    pathName: MIDDLE_EAST_PAGE,
    region: MIDDLE_EAST_KEY,
    workdayPath: `${WORKDAY_EN_PATH}locationCountry=50423b5190ad49bb89e94cd58dfaad69&locationCountry=7b4fa1f369bd4604ba3692682fcbe345`,
    isVisible: true
}
]