import React, { FC } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import parse from "html-react-parser";


import SimpleImage from "../../Shared/Media/SimpleImage";
import NissanFamilyImage from "../../../assets/images/nissanfamily.webp";
import NissanFamilyImage1 from "../../../assets/images/nissanfamily_1.webp";
import Heading from "../../Shared/Common/Heading";
import { FormattedMessage } from "react-intl";

const items = [{
  id: 1,
  src: NissanFamilyImage1,
  isIntl: false,
  heading: "page.meet_our_people.nissan_family_diago",
  description: "page.meet_our_people.nissan_family_diago_description"
}, {
  id: 2,
  src: NissanFamilyImage,
  isIntl: true,
  heading: "page.meet_our_people.nissan_family_ballards",
  description: "page.meet_our_people.nissan_family_ballards_description"
}]

const Nissanfamily: FC = () => {
  return (
    <div className="nissan-family-area">
      <Splide
        options={{
          arrows: false,
          pagination: true,
          width: "100%",
          type: "loop",
          perPage: 1,
          perMove: 1,
          gap: "15px",
          rewind: false,
          autoplay: true,
          pauseOnHover: false,
          resetProgress: false,
          interval: 5000,
          classes: {
            pagination: "container custom-pagination",
          },
        }}
      >
        {
          items.map(item => {
            return (
              <SplideSlide key={item.id}>
                <Container >
                  <Heading type="section-heading">
                    <FormattedMessage id={item.heading} />
                  </Heading>
                  <Row>
                    <Col md={12} lg={6} className="family-image">
                      <SimpleImage src={item.src} />
                    </Col>
                    <Col md={12} lg={6}>
                      <p>
                        <FormattedMessage id={item.description} values={{
                          br: <br />, website_link: (
                            <a href="https://www.nissan-global.com/EN/COMPANY/LIFE_AT_NISSAN/FROM_NISSAN_SOUTH_AMERICA_TO_DUBAI/" target="_blank" rel="noreferrer">
                              <FormattedMessage id="page.common.learn_more" />
                            </a>
                          ),
                        }} />
                      </p>
                    </Col>
                  </Row>
                </Container>
              </SplideSlide>
            )
          })
        }
      </Splide>
    </div>
  );
};
export default Nissanfamily;
