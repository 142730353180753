import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Row, Col, Container } from "react-bootstrap";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import SimpleImage from "../../Media/SimpleImage";
import Heading from "../../Common/Heading";
import { Anchor } from "../../Common/Anchor";
import AnchorImage from "../../../../assets/images/middle_east/testimonial_anchor.webp"
interface TestimonialI {
    id: number,
    src: string;
    userName: string;
    userDesignation: string;
    description: string;
    href?: string;
    removeUsername?: boolean
}
interface Props {
    items: TestimonialI[]
}

const TestimonialItem = ({ src, description, userDesignation, userName, href, removeUsername }: TestimonialI) => {
    return (
        <div className="row nissan-carousel-item-inner d-flex no-gutters">
            <div className="col-12 order-1 order-md-0 col-md-8 carousel-content">
                <p>
                    <FormattedMessage id={description} />
                </p>
                {!removeUsername && (
                    <div className="nissan-carousel-user-data">
                        <div className="userimg"><SimpleImage src={src} /></div>
                        <div className="user-detail">
                            <div className="user-name">{userName}</div>
                            <p className="user-designation">
                                <FormattedMessage id={userDesignation} />
                            </p>
                        </div>
                    </div>
                )}
            </div>
            <div className="col-12 order-0 order-md-1 col-md-4 testimonial-image-section">
                {
                    href ? (
                        <Anchor href={href} external={true}>
                            <SimpleImage src={src} className="nissan-carousel-profile-img" />
                            <div className="overlay-anchor"><SimpleImage src={AnchorImage} /></div>
                        </Anchor>
                    ) : <SimpleImage src={src} className="nissan-carousel-profile-img" />
                }
            </div>
        </div>
    )
}

const Testimonial: FC<Props> = ({ items }) => {
    return (
        <section className="america-testimonial-area">
            <Container>
                <Row className="align-items-center mb-3">
                    <Col>
                        <Heading type="detailed-sub-heading">
                            <FormattedMessage id="page.testimonials.we_love_you_what_we_do" />
                        </Heading>
                        <Heading type="section-heading">
                            <FormattedMessage id="page.testimonials.hear_from_our_employess" />
                        </Heading>
                    </Col>
                </Row>

                <div className="nissan-carousel-container mt-3">
                    <div className="nissan-carousel-item">
                        <Splide
                            options={{
                                arrows: false,
                                pagination: true,
                                width: "100%",
                                // type   : 'loop',
                                // padding: '10%',
                                gap: 20,
                                perPage: 1,
                                perMove: 1,
                                rewind: true,
                                autoplay: true,
                                pauseOnHover: false,
                                resetProgress: false,
                                interval: 6000
                            }}
                            aria-labelledby={"americas-testimonials-sections"}
                        >
                            {
                                items.map(item => {
                                    return (
                                        <SplideSlide key={item.id}>
                                            <TestimonialItem {...item} />
                                        </SplideSlide>
                                    )
                                })
                            }
                        </Splide>
                    </div>
                </div>
            </Container>
        </section>
    );
};
export default Testimonial;
