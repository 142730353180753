import { WORKDAY_EN_PATH, WORKDAY_JP_PATH } from "../../../interfaces/Static/routing_paths";

const USA_JOB_LINK = `${WORKDAY_EN_PATH}locationCountry=8b705da2becf43cfaccc091da0988ab2&q=`;
const NML_JOB_LINK = `${WORKDAY_JP_PATH}locationCountry=8b705da2becf43cfaccc091da0988ab2&q=`;

export const ENGINEERING_LINKS = [{
    id: 1,
    name: "page.common.r_and_d",
    links: [{
        id: 1,
        name: "page.common.research",
        href: `${USA_JOB_LINK}総合研究`,
        href_jp: `${NML_JOB_LINK}総合研究`,
    }, {
        id: 2,
        name: "page.common.experimental_evaluation_development",
        href: `${USA_JOB_LINK}実験・評価・認証開発`,
        href_jp: `${NML_JOB_LINK}実験・評価・認証開発`,
    }, {
        id: 3,
        name: "page.common.advanced_vehicle_development",
        href: `${USA_JOB_LINK}車両先行開発`,
        href_jp: `${NML_JOB_LINK}車両先行開発`,
    }, {
        id: 4,
        name: "page.common.vehicle_development",
        href: `${USA_JOB_LINK}車両開発マネジメント`,
        href_jp: `${NML_JOB_LINK}車両開発マネジメント`,
    }, {
        id: 5,
        name: "page.common.power_train_development",
        href: `${USA_JOB_LINK}パワートレイン開発（EV/ePower）`,
        href_jp: `${NML_JOB_LINK}パワートレイン開発（EV/ePower）`,
    }, {
        id: 6,
        name: "page.common.electronic_chassis_development",
        href: `${USA_JOB_LINK}電子電装・シャシーシステム開発`,
        href_jp: `${NML_JOB_LINK}電子電装・シャシーシステム開発`,
    }, {
        id: 7,
        name: "page.common.interior_exterior_development",
        href: `${USA_JOB_LINK}内外装・車体設計開発`,
        href_jp: `${NML_JOB_LINK}内外装・車体設計開発`,
    }, {
        id: 8,
        name: "page.common.connected_car_development",
        href: `${USA_JOB_LINK}コネクテッドカー＆サービス開発`,
        href_jp: `${NML_JOB_LINK}コネクテッドカー＆サービス開発`,
    }, {
        id: 9,
        name: "page.common.second_new_grad",
        href: `${USA_JOB_LINK}第二新卒`,
        href_jp: `${NML_JOB_LINK}第二新卒`,
    }]
}, {
    id: 2,
    name: "page.common.manufacturing",
    links: [{
        id: 1,
        name: "page.common.vehicle_production_technology",
        href: `${USA_JOB_LINK}車両生産技術`,
        href_jp: `${NML_JOB_LINK}車両生産技術`,
    }, {
        id: 2,
        name: "page.common.powertrain_production_technology",
        href: `${USA_JOB_LINK}パワートレイン生産技術`,
        href_jp: `${NML_JOB_LINK}パワートレイン生産技術`,
    }, {
        id: 3,
        name: "page.common.quality_and_safety",
        href: `${USA_JOB_LINK}品質・安全`,
        href_jp: `${NML_JOB_LINK}品質・安全`,
    }, {
        id: 4,
        name: "page.common.production_system_promotion",
        href: `${USA_JOB_LINK}生産システムプロモーション`,
        href_jp: `${NML_JOB_LINK}生産システムプロモーション`,
    }, {
        id: 5,
        name: "page.common.supply_chain_management",
        href: `${USA_JOB_LINK}サプライチェーンマネジメント`,
        href_jp: `${NML_JOB_LINK}サプライチェーンマネジメント`,
    }, {
        id: 6,
        name: "page.common.occupational_safety_and_health",
        href: `${USA_JOB_LINK}労働安全衛生`,
        href_jp: `${NML_JOB_LINK}労働安全衛生`,
    }]
}, {
    id: 3,
    name: "page.common.quality_assurance",
    links: [{
        id: 1,
        name: "page.common.quality_audit",
        href: `${USA_JOB_LINK}品質監査`,
        href_jp: `${NML_JOB_LINK}品質監査`,
    }, {
        id: 2,
        name: "page.common.product_quality_management",
        href: `${USA_JOB_LINK}プロダクト・クオリティ・マネージメント`,
        href_jp: `${NML_JOB_LINK}プロダクト・クオリティ・マネージメント`,
    }, {
        id: 3,
        name: "page.common.market_quality_improvement",
        href: `${USA_JOB_LINK}市場品質改善`,
        href_jp: `${NML_JOB_LINK}市場品質改善`,
    }, {
        id: 4,
        name: "page.common.domestic_services",
        href: `${USA_JOB_LINK}国内サービス`,
        href_jp: `${NML_JOB_LINK}国内サービス`,
    }, {
        id: 5,
        name: "page.common.quality_assurance",
        href: `${USA_JOB_LINK}品質保証`,
        href_jp: `${NML_JOB_LINK}品質保証`,
    }, {
        id: 6,
        name: "page.common.planning_and_supervision",
        href: `${USA_JOB_LINK}企画・監理`,
        href_jp: `${NML_JOB_LINK}企画・監理`,
    },
    // {
    //     id: 7,
    //     name: "page.common.powertrain_development",
    //     href: `${USA_JOB_LINK}`,
    //     href_jp: `${NML_JOB_LINK}`,
    // }, 
    {
        id: 8,
        name: "page.common.chief_vehicle_assessment_specialist",
        href: `${USA_JOB_LINK}CVAS（チーフビークルアセスメントスペシャリスト）`,
        href_jp: `${NML_JOB_LINK}CVAS（チーフビークルアセスメントスペシャリスト）`,
    }]
}, {
    id: 4,
    name: "page.common.accounting_and_finance",
    links: [{
        id: 1,
        name: "page.common.new_vehicle_capital_investment_management",
        href: `${USA_JOB_LINK}新車設備投資管理`,
        href_jp: `${NML_JOB_LINK}新車設備投資管理`,
    }, {
        id: 2,
        name: "page.common.alliance_costing",
        href: `${USA_JOB_LINK}アライアンスコスティング`,
        href_jp: `${NML_JOB_LINK}アライアンスコスティング`,
    }, {
        id: 3,
        name: "page.common.managerial_accounting",
        href: `${USA_JOB_LINK}管理会計`,
        href_jp: `${NML_JOB_LINK}管理会計`,
    }, {
        id: 4,
        name: "page.common.new_vehicle_development_cost_management",
        href: `${USA_JOB_LINK}新車開発費管理`,
        href_jp: `${NML_JOB_LINK}新車開発費管理`,
    }]
}, {
    id: 5,
    name: "page.common.design",
    links: [{
        id: 1,
        name: "page.common.exterior_design",
        href: `${USA_JOB_LINK}エクステリアデザイン`,
        href_jp: `${NML_JOB_LINK}エクステリアデザイン`,
    }, {
        id: 2,
        name: "page.common.interior_design",
        href: `${USA_JOB_LINK}インテリアデザイ`,
        href_jp: `${NML_JOB_LINK}インテリアデザイ`,
    }, {
        id: 3,
        name: "page.common.color_design",
        href: `${USA_JOB_LINK}カラーデザイン`,
        href_jp: `${NML_JOB_LINK}カラーデザイン`,
    }, {
        id: 4,
        name: "page.common.ux_ui_design",
        href: `${USA_JOB_LINK}UXUIデザイン`,
        href_jp: `${NML_JOB_LINK}UXUIデザイン`,
    }, {
        id: 5,
        name: "page.common.fashion_design",
        href: `${USA_JOB_LINK}ファッションデザイン`,
        href_jp: `${NML_JOB_LINK}ファッションデザイン`,
    }, {
        id: 6,
        name: "page.common.clay_model",
        href: `${USA_JOB_LINK}クレイモデル`,
        href_jp: `${NML_JOB_LINK}クレイモデル`,
    }, {
        id: 7,
        name: "page.common.digital_model",
        href: `${USA_JOB_LINK}デジタルモデル`,
        href_jp: `${NML_JOB_LINK}デジタルモデル`,
    }, {
        id: 8,
        name: "page.common.front_end_engineer",
        href: `${USA_JOB_LINK}フロントエンドエンジニア`,
        href_jp: `${NML_JOB_LINK}フロントエンドエンジニア`,
    }, {
        id: 9,
        name: "page.common.spatial_designer",
        href: `${USA_JOB_LINK}空間デザイナー`,
        href_jp: `${NML_JOB_LINK}空間デザイナー`,
    }]
}, {
    id: 6,
    name: "page.common.global_information_systems",
    links: [{
        id: 1,
        name: "page.common.strategy_planning_and_control",
        href: `${USA_JOB_LINK}ストラテジープランニング・コントロール`,
        href_jp: `${NML_JOB_LINK}ストラテジープランニング・コントロール`,
    }, {
        id: 2,
        name: "page.common.project_delivery",
        href: `${USA_JOB_LINK}プロジェクトデリバリー`,
        href_jp: `${NML_JOB_LINK}プロジェクトデリバリー`,
    }, {
        id: 3,
        name: "page.common.infrastructure_engineer_architect",
        href: `${USA_JOB_LINK}インフラストラクチャーエンジニア・アーキテクト`,
        href_jp: `${NML_JOB_LINK}インフラストラクチャーエンジニア・アーキテクト`,
    }, {
        id: 4,
        name: "page.common.digital_transformation_promotion",
        href: `${USA_JOB_LINK}デジタルトランスフォーメーションプロモーション`,
        href_jp: `${NML_JOB_LINK}デジタルトランスフォーメーションプロモーション`,
    }, {
        id: 5,
        name: "page.common.cyber_security",
        href: `${USA_JOB_LINK}サイバーセキュリティ`,
        href_jp: `${NML_JOB_LINK}サイバーセキュリティ`,
    }]
}, {
    id: 7,
    name: "page.common.global_after_sales",
    links: [{
        id: 1,
        name: "page.common.global_after_sales_engineering",
        href: `${USA_JOB_LINK}グローバルアフターセールスエンジニアリング`,
        href_jp: `${NML_JOB_LINK}グローバルアフターセールスエンジニアリング`,
    }, {
        id: 2,
        name: "page.common.planning_strategy",
        href: `${USA_JOB_LINK}企画戦略`,
        href_jp: `${NML_JOB_LINK}企画戦略`,
    }, {
        id: 3,
        name: "page.common.supply_chain_management",
        href: `${USA_JOB_LINK}サプライチェーンマネジメント`,
        href_jp: `${NML_JOB_LINK}サプライチェーンマネジメント`,
    }, {
        id: 5,
        name: "page.common.global_aftersales_connected",
        href: `${USA_JOB_LINK}コネクテッド`,
        href_jp: `${NML_JOB_LINK}コネクテッド`,
    }]
}, {
    id: 8,
    name: "page.common.construction_management",
    links: [{
        id: 1,
        name: "page.common.construction_management",
        href: `${USA_JOB_LINK}コンストラクションマネジメント`,
        href_jp: `${NML_JOB_LINK}コンストラクションマネジメント`,
    }]
}]