import { FC } from "react";
import { Row, Col } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import SimpleImage from "../../Shared/Media/SimpleImage";

import Story1 from "../../../assets/images/story-1.webp";
import Story2 from "../../../assets/images/story-2.webp";
import Story3 from "../../../assets/images/story-3.webp";
import Story4 from "../../../assets/images/story-4.webp";
import Story5 from "../../../assets/images/story-5.webp";
import Story6 from "../../../assets/images/story-6.webp";

import { Anchor } from "../../Shared/Common/Anchor";

const items = [
  {
    id: 1,
    name: "page.common.stories_1",
    src: Story1,
    href: "https://europe.nissanstories.com/en/releases/spring-into-this-season-with-nissans-e-4orce-technology",
  },
  {
    id: 2,
    name: "page.common.stories_2",
    src: Story2,
    href: "https://usa.nissanstories.com/en-US/releases/an-empowering-journey-begins",
  },
  {
    id: 3,
    name: "page.common.stories_3",
    src: Story3,
    href: "https://global.nissanstories.com/en/releases/nissan-yokohama-lab",
    href_jp:
      "https://global.nissanstories.com/ja-JP/releases/nissan-yokohama-lab",
   },
  {
    id: 4,
    name: "page.common.stories_4",
    src: Story4,
    href: "https://global.nissanstories.com/en/releases/the-science-of-scent",
    href_jp: "https://global.nissanstories.com/ja-JP/releases/the-science-of-scent",
  },
  {
    id: 5,
    name: "page.common.stories_5",
    src: Story5,
    href: "https://global.nissanstories.com/en/releases/nissan-ai-technology",
    href_jp:
      "https://global.nissanstories.com/ja-JP/releases/nissan-ai-technology",
  },
  {
    id: 6,
    name: "page.common.stories_6",
    src: Story6,
    href: "https://global.nissanstories.com/en/releases/nissan-research-center",
    href_jp:
      "https://global.nissanstories.com/ja-JP/releases/nissan-research-center",
  },
];
const StoriesDesktop: FC = () => {
  const intl = useIntl();
  return (
    <Row className="mt-3">
      {items.map((item, index) => {
        return (
          <Col lg={4} md={6} xs={12} key={index}>
            <Anchor href={item.href} href_jp={item.href_jp} external={true}>
              <div className="card">
                <SimpleImage src={item.src} className="grid-img" />
                <h6>
                  {intl.formatMessage({ id: item.name }).substring(0, 53)}
                  {intl.formatMessage({ id: item.name }).length > 53 && "..."}
                </h6>
              </div>
            </Anchor>
          </Col>
        );
      })}
    </Row>
  );
};
export default StoriesDesktop;
