/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import { FIND_NLM_NAME, FIND_NLM_PAGES, FIND_REGIONAL_BY_LOCATIONS } from "../../../utils";
import { ACTIVE_REGIONS } from "../../../utils/_active_regions";

const REGIONS = [
    {
        id: 1,
        name_fm: "page.common.global",
        value: '/',
        pathName: '/',
        isVisible: true
    },
    ...ACTIVE_REGIONS
];

const languages = [
    {
        id: 1,
        name: "page.common.eng_code",
        value: "en",
    },
    {
        id: 2,
        name: "page.common.jpn_code",
        value: "ja",
    },
];

interface Props {
    language: string;
    onSelect: (eventKey: any) => void;
}


const GlobalMenu: FC<Props> = ({ language, onSelect }) => {

    const [regions, setRegions] = useState(REGIONS);

    const location = useLocation();


    useEffect(() => {
        let pathName = '/';
        const findRegional = FIND_REGIONAL_BY_LOCATIONS(location.pathname);
        if (findRegional) {
            pathName = findRegional.pathName;
        }

        const regions_new = regions.map((item, i) => {
            return { ...item, isVisible: !(item.pathName === pathName) };
        });

        setRegions(regions_new);

    }, [location])

    const getLanguageText = () => {
        let x = languages.find((x) => x.value === language);
        return x?.name ? x.name : "";
    };

    const onSelectHref = (eventKey: any) => {
        onSelect('en'); // HOT FIX for regional live
        window.open(eventKey, '_blank')
    }

    return (
        <div className="global-menu">
            <Dropdown onSelect={onSelectHref}>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <FormattedMessage id={FIND_NLM_NAME(location.pathname)} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {regions.filter(x => x.isVisible).map((item) => {
                        return (
                            <Dropdown.Item
                                key={item.id}
                                eventKey={item.value}
                            >
                                <FormattedMessage id={item.name_fm} />
                            </Dropdown.Item>
                        );
                    })}
                </Dropdown.Menu>
            </Dropdown>
            &nbsp;|&nbsp;
            {
                (!FIND_NLM_PAGES(location.pathname) || FIND_REGIONAL_BY_LOCATIONS(location.pathname)?.translation) ?
                    <Dropdown onSelect={onSelect}>
                        <Dropdown.Toggle
                            variant="success"
                            id="dropdown-language"
                            className="text-uppercase"
                        >
                            <FormattedMessage id={getLanguageText()} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>

                            {languages.map((item) => {
                                return (
                                    <Dropdown.Item
                                        key={item.id}
                                        eventKey={item.value}
                                        className="text-uppercase"
                                    >
                                        <FormattedMessage id={item.name} />
                                    </Dropdown.Item>
                                );
                            })}
                        </Dropdown.Menu>
                    </Dropdown> : (
                        <div className="dropdown"><div className="btn" style={{ minWidth: '45px' }}>Eng</div></div>
                    )
            }
        </div>
    );
};
export default GlobalMenu;
