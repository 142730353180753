import React, { useState, FC } from "react";
import { IntlProvider } from "react-intl";

import en from "../Localization/en.json";
import ja from "../Localization/ja.json";
let defaultLang = "en";
const preferredLanguage = localStorage.getItem('locale');
if(preferredLanguage){
  defaultLang = preferredLanguage;
}else{
  if (navigator.language && navigator.language === 'ja'){
    defaultLang = 'ja';
  }
  localStorage.setItem('locale', defaultLang);
}
// const  usrlang = navigator.language;

// const defaultLang = "en";
const languages = { en, ja };
// const intlLanguages = languages[
//   defaultLang as keyof typeof languages
// ] as Record<string, string>;

interface Props {
  children: React.ReactNode;
}

interface ContextProps {
  locale: string;
  selectLanguage: (e: any) => void;
}

export const Context = React.createContext<ContextProps | null>(null);

const LocalizationWrapper: FC<Props> = ({ children }) => {
  const [locale, setLocale] = useState(defaultLang);
  const [messages, setMessages] = useState(defaultLang);
  const selectLanguage = (e: string) => {
    const newLocale = e;
    setLocale(newLocale);
    localStorage.setItem('locale', newLocale);
    if (newLocale === "en") {
      setMessages("en");
    } else {
      setMessages("ja");
    }
  };
  return (
    <>
      <Context.Provider value={{ locale, selectLanguage }}>
        <IntlProvider
          key={messages}
          locale={messages}
          messages={languages[messages === "en" ? "en" : "ja"]}
        >
          {children}
        </IntlProvider>
      </Context.Provider>
    </>
  );
};
export default LocalizationWrapper;
