import { AFRICA_PAGE, AMERICAS_PAGE, ASEAN_PAGE, BASENAME_PAGE, EUROPE_PAGE, MIDDLE_EAST_PAGE, NML_PAGE, WORKDAY_EN_PATH } from "./routing_paths";

export interface RegionalItemType {
  id: number;
  label: string;
  key: string;
}

export const ALLIANCE_WORKDAY_PATH = `${WORKDAY_EN_PATH}`;

export const JOBS_BY_REGION = [
  {
    id: 1,
    href: `${BASENAME_PAGE}${NML_PAGE}`,
    title: "page.common.japan",
  },
  {
    id: 2,
    href: `${BASENAME_PAGE}${AMERICAS_PAGE}`,
    title: "page.common.south_america",
  },
  {
    id: 3,
    href: `${BASENAME_PAGE}${ASEAN_PAGE}`,
    title: "page.common.asia_asean",
  },
  {
    id: 4,
    href: `${BASENAME_PAGE}${EUROPE_PAGE}`,
    title: "page.common.europe",
  },
  {
    id: 5,
    href: `${ALLIANCE_WORKDAY_PATH}locationCountry=c4f78be1a8f14da0ab49ce1162348a5e`,
    title: "page.country.india",
  },
  {
    id: 6,
    href: "https://www.nissan.com.au/about-nissan/careers-plus.html",
    title: "page.common.oceania",
  },
  {
    id: 7,
    href: `${BASENAME_PAGE}${AFRICA_PAGE}`,
    title: "page.common.africa",
  },
  {
    id: 8,
    href:  `${BASENAME_PAGE}${AMERICAS_PAGE}`,
    title: "page.common.north_america",
  },
  {
    id: 9,
    href:`${BASENAME_PAGE}${MIDDLE_EAST_PAGE}`,
    title: "page.common.middle_east_gulf",
  },
];

export const GLOBAL_LOCATIONS = [
  {
    id: 1,
    title: "page.common.north_america",
    href: "https://www.nissan-global.com/EN/COMPANY/PROFILE/EN_ESTABLISHMENT/NORTH_AMERICA/",
    href_jp:
      "https://www.nissan-global.com/JP/COMPANY/PROFILE/EN_ESTABLISHMENT/NORTH_AMERICA/",
  },
  {
    id: 2,
    title: "page.common.europe",
    href: "https://www.nissan-global.com/EN/COMPANY/PROFILE/EN_ESTABLISHMENT/EUROPE/",
    href_jp:
      "https://www.nissan-global.com/JP/COMPANY/PROFILE/EN_ESTABLISHMENT/EUROPE/",
  },
  {
    id: 3,
    title: "page.common.asia",
    href: "https://www.nissan-global.com/EN/COMPANY/PROFILE/EN_ESTABLISHMENT/ASIA/",
    href_jp:
      "https://www.nissan-global.com/JP/COMPANY/PROFILE/EN_ESTABLISHMENT/ASIA/",
  },
  {
    id: 4,
    title: "page.common.oceania",
    href: "https://www.nissan-global.com/EN/COMPANY/PROFILE/EN_ESTABLISHMENT/OCEANIA/",
    href_jp:
      "https://www.nissan-global.com/JP/COMPANY/PROFILE/EN_ESTABLISHMENT/OCEANIA/",
  },
  {
    id: 5,
    title: "page.common.south_america",
    href: "https://www.nissan-global.com/EN/COMPANY/PROFILE/EN_ESTABLISHMENT/LATIN_AMERICA/",
    href_jp:
      "https://www.nissan-global.com/JP/COMPANY/PROFILE/EN_ESTABLISHMENT/LATIN_AMERICA/",
  },
  {
    id: 6,
    title: "page.common.middle_east_gulf",
    href: "https://www.nissan-global.com/EN/COMPANY/PROFILE/EN_ESTABLISHMENT/MIDDLE_EAST/",
    href_jp:
      "https://www.nissan-global.com/JP/COMPANY/PROFILE/EN_ESTABLISHMENT/MIDDLE_EAST/",
  },
  {
    id: 7,
    title: "page.common.africa",
    href: "https://www.nissan-global.com/EN/COMPANY/PROFILE/EN_ESTABLISHMENT/AFRICA/",
    href_jp:
      "https://www.nissan-global.com/JP/COMPANY/PROFILE/EN_ESTABLISHMENT/AFRICA/",
  },
  {
    id: 8,
    title: "page.common.japan",
    href: "https://www.nissan-global.com/EN/COMPANY/PROFILE/ESTABLISHMENT/",
    href_jp: "https://www.nissan-global.com/JP/COMPANY/PROFILE/ESTABLISHMENT/",
  },
];

const redirectUrl = `${ALLIANCE_WORKDAY_PATH}locationCountry=`;

export const RegionalItemsJapan: RegionalItemType[] = [
  {
    id: 1,
    label: "アルゼンチン",
    key: `${redirectUrl}e42ad5eac46d4cc9b367ceaef42577c5`,
  },
  {
    id: 2,
    label: "オーストラリア",
    key: `https://www.nissan.com.au/about-nissan/careers-plus.html`,
  },
  {
    id: 4,
    label: "ベルギー",
    key: `${redirectUrl}a04ea128f43a42e59b1e6a19e8f0b374`,
  },
  {
    id: 5,
    label: "ブラジル",
    key: `${redirectUrl}1a29bb1357b240ab99a2fa755cc87c0e`,
  },
  {
    id: 6,
    label: "カナダ",
    key: `${redirectUrl}a30a87ed25634629aa6c3958aa2b91ea`,
  },
  {
    id: 7,
    label: "チリ",
    key: `${redirectUrl}53fe09ef12b9408682a1d2439823f2e0`,
  },
  {
    id: 8,
    label: "中国",
    key: `${redirectUrl}6cb77610a8a543aea2d6bc10457e35d4`,
  },
  {
    id: 10,
    label: "デンマーク",
    key: `${redirectUrl}49ab063f422741e2aef271de00efeac8`,
  },
  {
    id: 11,
    label: "エジプト",
    key: `${redirectUrl}d865e83093ad42319653b08e61f7db49`,
  },
  {
    id: 12,
    label: "エストニア",
    key: `${redirectUrl}038b0482bfea403abb61c9bcc3d7eb60`,
  },
  {
    id: 13,
    label: "フィンランド",
    key: `${redirectUrl}0afb2fa656da42e8bfb6d47bd24a26fa`,
  },
  {
    id: 14,
    label: "フランス",
    key: `${redirectUrl}54c5b6971ffb4bf0b116fe7651ec789a`,
  },
  {
    id: 15,
    label: "ドイツ",
    key: `${redirectUrl}dcc5b7608d8644b3a93716604e78e995`,
  },
  {
    id: 16,
    label: "ハンガリー",
    key: `${redirectUrl}9db257f5937e4421b2fac64eec6832f8`,
  },
  {
    id: 17,
    label: "インド",
    key: `${redirectUrl}c4f78be1a8f14da0ab49ce1162348a5e`,
  },
  {
    id: 177,
    label: "インド（日産デジタル）",
    key: `https://www.nissanmotor.jobs/ami/india/ndi/index.html`,
  },
  {
    id: 18,
    label: "インドネシア",
    key: `${redirectUrl}b31234dbcdda4da9ba8fa073c5944e36`,
  },
  {
    id: 19,
    label: "イタリア",
    key: `${redirectUrl}8cd04a563fd94da7b06857a79faaf815`,
  },
  {
    id: 20,
    label: "日本",
    key: `${redirectUrl}8b705da2becf43cfaccc091da0988ab2`,
  },
  {
    id: 21,
    label: "韓国",
    key: `${redirectUrl}710afe3b42ce44f8b995e1ff21ca0a3e`,
  },
  {
    id: 22,
    label: "ラトビア",
    key: `${redirectUrl}1c026f3b1b8640d8bdfcb95466663e4d`,
  },
  {
    id: 23,
    label: "リトアニア",
    key: `${redirectUrl}8a0328effd25491fb8e6a08801f08e94`,
  },
  {
    id: 24,
    label: "メキシコ",
    key: `${redirectUrl}e2adff9272454660ac4fdb56fc70bb51`,
  },
  {
    id: 40,
    label: "オランダ",
    key: `${redirectUrl}9696868b09c64d52a62ee13b052383cc`,
  },
  {
    id: 25,
    label: "ニュージーランド",
    key: `https://www.nissan.com.au/about-nissan/careers-plus.html`,
  },
  {
    id: 26,
    label: "ノルウェー",
    key: `${redirectUrl}d07f8ca8625e4345b98a91d0558b872a`,
  },
  {
    id: 27,
    label: "ペルー",
    key: `${redirectUrl}0eb156ca580c4db786a7894bdaa77450`,
  },
  {
    id: 28,
    label: "フィリピン",
    key: `${redirectUrl}e56f1daf83e04bacae794ba5c5593560`,
  },
  {
    id: 29,
    label: "ポーランド",
    key: `${redirectUrl}131d5ac7e3ee4d7b962bdc96e498e412`,
  },
  {
    id: 30,
    label: "ポルトガル",
    key: `${redirectUrl}2e8c5034deb045d49315417c347472ca`,
  },
  {
    id: 32,
    label: "サウジアラビア",
    key: `${redirectUrl}50423b5190ad49bb89e94cd58dfaad69`,
  },
  {
    id: 33,
    label: "シンガポール",
    key: `${redirectUrl}80938777cac5440fab50d729f9634969`,
  },
  {
    id: 35,
    label: "南アフリカ",
    key: `${redirectUrl}4db3c26940b944919b83612cc1193eed`,
  },
  {
    id: 36,
    label: "スペイン",
    key: `${redirectUrl}bd34c524a6a04ae6915f5d96fa086199`,
  },
  {
    id: 37,
    label: "スウェーデン",
    key: `${redirectUrl}6a800a4736884df5826858d435650f45`,
  },
  {
    id: 39,
    label: "タイ",
    key: `${redirectUrl}873d0f604e3b458c990cb4d83a5c0f14`,
  },
  {
    id: 41,
    label: "トルコ",
    key: `${redirectUrl}c2e3bac5bbbb47b29dfc6e8b56a1586e`,
  },
  {
    id: 41,
    label: "ウクライナ",
    key: `${redirectUrl}a4051ef996ac40778d4c79e3f2dedfd2`,
  },
  {
    id: 42,
    label: "アラブ首長国連邦",
    key: `${redirectUrl}7b4fa1f369bd4604ba3692682fcbe345`,
  },
  {
    id: 43,
    label: "イギリス",
    key: `${redirectUrl}29247e57dbaf46fb855b224e03170bc7`,
  },
  {
    id: 44,
    label: "イギリス（工場）",
    key: `https://careersatnissan.co.uk/`,
  },
  {
    id: 45,
    label: "アメリカ",
    key: `${redirectUrl}bc33aa3152ec42d4995f4791a106ed09`,
  },
];

export const RegionalItems: RegionalItemType[] = [
  {
    id: 1,
    label: "Argentina",
    key: `${redirectUrl}e42ad5eac46d4cc9b367ceaef42577c5`,
  },
  {
    id: 2,
    label: "Australia",
    key: `https://www.nissan.com.au/about-nissan/careers-plus.html`,
  },
  {
    id: 4,
    label: "Belgium",
    key: `${redirectUrl}a04ea128f43a42e59b1e6a19e8f0b374`,
  },
  {
    id: 5,
    label: "Brazil",
    key: `${redirectUrl}1a29bb1357b240ab99a2fa755cc87c0e`,
  },
  {
    id: 6,
    label: "Canada",
    key: `${redirectUrl}a30a87ed25634629aa6c3958aa2b91ea`,
  },
  {
    id: 7,
    label: "Chile",
    key: `${redirectUrl}53fe09ef12b9408682a1d2439823f2e0`,
  },
  {
    id: 8,
    label: "China",
    key: `${redirectUrl}6cb77610a8a543aea2d6bc10457e35d4`,
  },
  {
    id: 10,
    label: "Denmark",
    key: `${redirectUrl}49ab063f422741e2aef271de00efeac8`,
  },
  {
    id: 11,
    label: "Egypt",
    key: `${redirectUrl}d865e83093ad42319653b08e61f7db49`,
  },
  {
    id: 12,
    label: "Estonia",
    key: `${redirectUrl}038b0482bfea403abb61c9bcc3d7eb60`,
  },
  {
    id: 13,
    label: "Finland",
    key: `${redirectUrl}0afb2fa656da42e8bfb6d47bd24a26fa`,
  },
  {
    id: 14,
    label: "France",
    key: `${redirectUrl}54c5b6971ffb4bf0b116fe7651ec789a`,
  },
  {
    id: 15,
    label: "Germany",
    key: `${redirectUrl}dcc5b7608d8644b3a93716604e78e995`,
  },
  {
    id: 16,
    label: "Hungary",
    key: `${redirectUrl}9db257f5937e4421b2fac64eec6832f8`,
  },
  {
    id: 17,
    label: "India",
    key: `${redirectUrl}c4f78be1a8f14da0ab49ce1162348a5e`,
  },
  {
    id: 177,
    label: "India (Nissan Digital)",
    key: `https://www.nissanmotor.jobs/ami/india/ndi/index.html`,
  },
  {
    id: 18,
    label: "Indonesia",
    key: `${redirectUrl}b31234dbcdda4da9ba8fa073c5944e36`,
  },
  {
    id: 19,
    label: "Italy",
    key: `${redirectUrl}8cd04a563fd94da7b06857a79faaf815`,
  },
  {
    id: 20,
    label: "Japan",
    key: `${redirectUrl}8b705da2becf43cfaccc091da0988ab2`,
  },
  {
    id: 21,
    label: "Korea",
    key: `${redirectUrl}710afe3b42ce44f8b995e1ff21ca0a3e`,
  },
  {
    id: 22,
    label: "Latvia",
    key: `${redirectUrl}1c026f3b1b8640d8bdfcb95466663e4d`,
  },
  {
    id: 23,
    label: "Lithuania",
    key: `${redirectUrl}8a0328effd25491fb8e6a08801f08e94`,
  },
  {
    id: 24,
    label: "Mexico",
    key: `${redirectUrl}e2adff9272454660ac4fdb56fc70bb51`,
  },
  {
    id: 40,
    label: "Netherlands",
    key: `${redirectUrl}9696868b09c64d52a62ee13b052383cc`,
  },
  {
    id: 25,
    label: "New Zealand",
    key: `https://www.nissan.com.au/about-nissan/careers-plus.html`,
  },
  {
    id: 26,
    label: "Norway",
    key: `${redirectUrl}d07f8ca8625e4345b98a91d0558b872a`,
  },
  {
    id: 27,
    label: "Peru",
    key: `${redirectUrl}0eb156ca580c4db786a7894bdaa77450`,
  },
  {
    id: 28,
    label: "Philippines",
    key: `${redirectUrl}e56f1daf83e04bacae794ba5c5593560`,
  },
  {
    id: 29,
    label: "Poland",
    key: `${redirectUrl}131d5ac7e3ee4d7b962bdc96e498e412`,
  },
  {
    id: 30,
    label: "Portugal",
    key: `${redirectUrl}2e8c5034deb045d49315417c347472ca`,
  },
  {
    id: 32,
    label: "Saudi Arabia",
    key: `${redirectUrl}50423b5190ad49bb89e94cd58dfaad69`,
  },
  {
    id: 33,
    label: "Singapore",
    key: `${redirectUrl}80938777cac5440fab50d729f9634969`,
  },
  {
    id: 35,
    label: "South Africa",
    key: `${redirectUrl}4db3c26940b944919b83612cc1193eed`,
  },
  {
    id: 36,
    label: "Spain",
    key: `${redirectUrl}bd34c524a6a04ae6915f5d96fa086199`,
  },
  {
    id: 37,
    label: "Sweden",
    key: `${redirectUrl}6a800a4736884df5826858d435650f45`,
  },
  {
    id: 39,
    label: "Thailand",
    key: `${redirectUrl}873d0f604e3b458c990cb4d83a5c0f14`,
  },
  {
    id: 41,
    label: "Turkey",
    key: `${redirectUrl}c2e3bac5bbbb47b29dfc6e8b56a1586e`,
  },
  {
    id: 41,
    label: "Ukraine",
    key: `${redirectUrl}a4051ef996ac40778d4c79e3f2dedfd2`,
  },
  {
    id: 42,
    label: "United Arab Emirates (UAE)",
    key: `${redirectUrl}7b4fa1f369bd4604ba3692682fcbe345`,
  },
  {
    id: 43,
    label: "United Kingdom (UK)",
    key: `${redirectUrl}29247e57dbaf46fb855b224e03170bc7`,
  },
  {
    id: 44,
    label: "United Kingdom (Manufacturing)",
    key: `https://careersatnissan.co.uk/`,
  },
  {
    id: 45,
    label: "United States of America (USA)",
    key: `${redirectUrl}bc33aa3152ec42d4995f4791a106ed09`,
  },
];

interface ListItem {
  id: number;
  title: string;
  href: string;
  href_jp?: string;
  external?: boolean | false;
}

interface SiteMap {
  id: number;
  title: string;
  list: ListItem[];
}

export const SiteMapLinks: SiteMap[] = [
  {
    id: 1,
    title: "page.common.about_us",
    list: [
      {
        id: 1,
        href: "https://www.nissan-global.com/EN/COMPANY/PROFILE/",
        href_jp: "https://www.nissan-global.com/JP/COMPANY/PROFILE/",
        title: "page.common.nissan_outline",
        external: true,
      },
      {
        id: 2,
        href: "https://www.nissan-global.com/EN/SUSTAINABILITY/SOCIAL/DIVERSITY/",
        href_jp:
          "https://www.nissan-global.com/JP/SUSTAINABILITY/SOCIAL/DIVERSITY/",
        title: "page.common.ceo_message",
        external: true,
      },
      {
        id: 3,
        href: "https://www.nissan-global.com/EN/SUSTAINABILITY/SOCIAL/DIVERSITY/",
        href_jp:
          "https://www.nissan-global.com/JP/SUSTAINABILITY/SOCIAL/DIVERSITY/",
        title: "page.common.purpose_mission_and_dna",
        external: true,
      },
      {
        id: 4,
        href: "https://www.nissan-global.com/EN/SUSTAINABILITY/GOVERNANCE/",
        href_jp: "https://www.nissan-global.com/JP/SUSTAINABILITY/GOVERNANCE/",
        title: "page.common.sustainability",
        external: true,
      },
      {
        id: 5,
        href: "https://www.nissan-global.com/EN/INNOVATION/",
        href_jp: "https://www.nissan-global.com/JP/INNOVATION/",
        title: "page.common.innovation",
        external: true,
      },
      {
        id: 6,
        href: "https://global.nissanstories.com/en",
        href_jp: "https://global.nissanstories.com/ja-JP",
        title: "page.common.nissan_stories",
        external: true,
      },
      {
        id: 7,
        href: "https://www.nissan-global.com/EN/COMPANY/PROFILE/EN_ESTABLISHMENT/",
        href_jp:
          "https://www.nissan-global.com/JP/COMPANY/PROFILE/EN_ESTABLISHMENT/",
        title: "page.common.global_locations",
        external: true,
      },
    ],
  },
  {
    id: 2,
    title: "page.common.job_by_region",
    list: JOBS_BY_REGION,
  },
  {
    id: 3,
    title: "page.common.global_locations",
    list: GLOBAL_LOCATIONS,
  },
  {
    id: 4,
    title: "page.common.meet_our_people",
    list: [
      {
        id: 1,
        href: "https://www.nissan-global.com/EN/COMPANY/LIFE_AT_NISSAN/",
        href_jp: "https://www.nissan-global.com/JP/COMPANY/LIFE_AT_NISSAN/",
        title: "page.common.life_at_nissan",
        external: false,
      },
    ],
  },
];
