import { FC, useEffect, useState } from "react";
import { Container } from "react-bootstrap";

import TwitterIcon from "../../../assets/images/twitter.svg";
import YouTubeIcon from "../../../assets/images/youtube.svg";
import LinkedInIcon from "../../../assets/images/linkedin.svg";
import FaceBookIcon from "../../../assets/images/facebook.svg";
import InstagramIcon from "../../../assets/images/instagram.svg";
import TiktokIcon from "../../../assets/images/tiktok.svg";

import SimpleImage from "../../Shared/Media/SimpleImage";
import { Anchor } from "../../Shared/Common/Anchor";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import { ASEAN_PAGE, EUROPE_PAGE, MIDDLE_EAST_PAGE, NML_PAGE } from "../../../interfaces/Static/routing_paths";
import { useIntl } from "react-intl";

const items = [
  {
    id: 1,
    href: "https://www.linkedin.com/company/nissan-motor-corporation/",
    src: LinkedInIcon,
  },
  {
    id: 2,
    href: "https://twitter.com/NissanMotor",
    src: TwitterIcon,
  },
  {
    id: 3,
    href: "https://www.youtube.com/Nissan",
    src: YouTubeIcon,
  },
  {
    id: 4,
    href: "https://www.facebook.com/nissan",
    src: FaceBookIcon,
  },
  {
    id: 5,
    href: "https://www.instagram.com/nissan/?hl=en",
    src: InstagramIcon,
  },
  {
    id: 6,
    href: "https://www.tiktok.com/@nissan",
    src: TiktokIcon,
  },
];

const FooterTop: FC = () => {
  const [socialItems, setSocialItems] = useState(items);
  const location = useLocation();
  const intl = useIntl();
  const { locale } = intl;
  useEffect(() => {
    let href = "https://www.youtube.com/Nissan";
    if (location.pathname.includes(EUROPE_PAGE)) {
      href = "https://www.youtube.com/@nissanineurope";
    } else if (location.pathname.includes(MIDDLE_EAST_PAGE)) {
      href = "https://www.youtube.com/@NissanMiddleEast";
    } else if (location.pathname.includes(NML_PAGE) && locale === 'ja') {
      href = "https://www.youtube.com/@NissanJapan";
    }
    let instagram = "https://www.instagram.com/nissan/?hl=en";
    if (location.pathname.includes(ASEAN_PAGE)) {
      instagram = "https://www.instagram.com/lifeatnissanasean/";
    } else if (location.pathname.includes(NML_PAGE) && locale === 'ja') {
      instagram = "https://www.instagram.com/accounts/login/?next=https%3A%2F%2Fwww.instagram.com%2Fnissanjapan%2F&is_from_rle";
    }

    let twitter = "https://twitter.com/NissanMotor";
    if (location.pathname.includes(NML_PAGE) && locale === 'ja') {
      twitter = "https://x.com/NissanJP?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor";
    }

    let facebook = "https://www.facebook.com/nissan";
    if (location.pathname.includes(NML_PAGE) && locale === 'ja') {
      facebook = "https://www.facebook.com/NissanJP/?locale=ja_JP";
    }

    const i = items.map((item) => {
      if (item.id === 3) {
        return {
          ...item,
          href
        };
      }
      if (item.id === 5) {
        return {
          ...item,
          href: instagram
        };
      }
      if (item.id === 2) {
        return {
          ...item,
          href: twitter
        };
      }
      if (item.id === 4) {
        return {
          ...item,
          href: facebook
        };
      }
      return item;
    });
    setSocialItems(i);
  }, [location])

  return (
    <Container>
      <h3>
        <FormattedMessage id="page.common.follow_us" />
      </h3>
      <div className="social-sharing">
        {socialItems.map((item, index) => {
          return (
            <Anchor external={true} key={item.id} href={item.href}>
              <SimpleImage src={item.src} />
            </Anchor>
          );
        })}
      </div>
    </Container>
  );
};

export default FooterTop;
