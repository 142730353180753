import {
    AFRICA_PAGE,
    AMERICAS_PAGE,
    ASEAN_PAGE,
    EUROPE_PAGE,
    MIDDLE_EAST_PAGE,
    NML_PAGE
} from "../interfaces/Static/routing_paths";
import { ACTIVE_REGIONS } from "./_active_regions";

const REGIONS = ACTIVE_REGIONS.map(x => x.pathName);

export const FIND_NLM_PAGES = (location: string) => {
    return REGIONS.some((region) => location.includes(region));
}

export const FIND_REGIONAL_BY_LOCATIONS = (location: string) => {
    return ACTIVE_REGIONS.find((region) => location.includes(region.pathName))
}

export const FIND_NLM_NAME = (location: string) => {
    if (location.includes(AMERICAS_PAGE)) {
        return "page.common.america"
    }
    if (location.includes(EUROPE_PAGE)) {
        return "page.common.europe"
    }
    if (location.includes(ASEAN_PAGE)) {
        return "page.common.asia_asean"
    }
    if (location.includes(AFRICA_PAGE)) {
        return "page.common.africa"
    }
    if (location.includes(NML_PAGE)) {
        return "page.common.japan"
    }
    if (location.includes(MIDDLE_EAST_PAGE)) {
        return "page.common.middle_east"
    }
    return "page.common.global";
}